import React from "react";

import { Paragraph1 } from "baseui/typography";

import Page from "../../components/internal-page";

const Steps = () => {
  return (
    <Page title="Этапы получения поддержки от ФРП" path="/frp/steps" parent="frp">
      <Paragraph1>Описание этапов</Paragraph1>
    </Page>
  );
};

export default Steps;
